.container {
  width: 100%;
  max-width: var(--max-width);
  display: grid;
  overflow: hidden;
  margin: 2rem auto 0rem auto;
  gap: 2.5rem;
}

.container .header {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  gap: 2rem;
}

.header .logo {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.header .logo .logo__image {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 144px;
  margin: -1rem;
}

.header .logo .logo__image svg {
  fill: var(--font-color);
}

.header .logo .logo__text {
  font-size: 3.5rem;
  margin-top: 0.5rem;
}

.header .navbar {
  width: 100%;
}

.header .navbar .menu__link {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.header .navbar .menu__link .nav__link a {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border: 1px solid var(--secondary-color);
  border-radius: 0.3rem;
  overflow: hidden;
}

.header .navbar .menu__link .nav__link a:hover {
  background: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
}

.header .navbar .menu__link .nav__link a svg {
  width: 1.5rem;
  height: 1.5rem;
}

.container .card {
  width: 100%;
  background: var(--bg-color-04);
  border-radius: 1rem 1rem 0rem 0rem;
  padding: 2rem 1.8rem 2rem 1.8rem;
}

.card .wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  display: -webkit-flex;
  align-items: center;
  gap: 2rem;
}

.card .wrapper .about {
  width: 100%;
  border: 2px solid var(--bg-color);
  border-radius: 0.7rem;
  padding: 0.9rem;
  text-align: center;
}

.card .wrapper .about h2 {
  font-size: 2.3rem;
  font-weight: 600;
  color: var(--font-color-02);
}

.card .wrapper .about p {
  font-size: 1rem;
  font-weight: 500;
  color: var(--font-color-02);
}

.card .wrapper .testimoni,
.card .wrapper .channel {
  width: 100%;
  background: var(--bg-color);
  padding: 1rem;
  border-radius: 0.7rem;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

.card .wrapper .menu {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  gap: 4rem;
  margin-top: 3rem;
  padding-bottom: 2rem;
}

.card .wrapper .menu .list {
  overflow: hidden;
  border-radius: 0.8rem;
  background: var(--bg-color);
}

.card .wrapper .menu .list .content {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  padding: 1.2rem;
  gap: 1rem;
}

.card .wrapper .menu .list .content .title {
  line-height: 2rem;
}

.card .wrapper .menu .list .content a {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  background: var(--primary-color);
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 1rem;
}
